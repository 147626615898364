import { json, redirect } from "@vercel/remix";
import type { ActionFunctionArgs, LoaderFunctionArgs } from "@vercel/remix";
import { Form, useActionData, useNavigation, NavLink } from "@remix-run/react";
// import { useActionData, useTransition } from "@remix-run/react";
// import * as Form from "@radix-ui/react-form";
import { Button, Text, TextField, RadixForm, Flex } from "@figment-fitness/ui";
// import {} from '@figment-fitness/ui/components/textField'
// import {Button} from '@figment-fitness/ui/components/button'

import { createSupabaseServerClient } from "@figment-fitness/database/supabase";
import { TitleBodyContainer } from "@figment-fitness/ui/components/title-body-container";
import { figmentRoutes } from "~/utils/figment/routes";
import { logout } from "./.server/figment/util/auth";
import DefaultErrorBoundary from "@figment-fitness/ui/components/error-boundary";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { supabaseClient, headers } = createSupabaseServerClient(request);
  const {
    data: { user },
  } = await supabaseClient.auth.getUser();

  // ログイン済みならtopへredirect
  if (user) {
    throw redirect(figmentRoutes.fitnessTenantList(), { headers });
  }

  return json({ success: false });
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const { supabaseClient, headers } = createSupabaseServerClient(request);
  const formData = await request.formData();
  const { data, error } = await supabaseClient.auth.signInWithPassword({
    email: formData.get("email") as string,
    password: formData.get("password") as string,
  });

  if (error) {
    return json(
      { success: false, error: "ログインに失敗しました" },
      { headers }
    );
  }

  const userID = data?.user?.id;

  if (!userID) {
    await logout(request);
    return json(
      { success: false, error: "ログインに失敗しました" },
      { headers }
    );
  }

  throw redirect(figmentRoutes.top(), { headers });
};

export const ErrorBoundary = DefaultErrorBoundary;

export default function Login() {
  const actionData = useActionData<typeof action>();
  const navigation = useNavigation();

  return (
    <TitleBodyContainer title="フィグメント管理画面ログイン" level={1}>
      <Flex direction="column" gap="4">
        <RadixForm.Root
          method="post"
          className="space-y-4 w-full max-w-md mx-auto mt-8"
        >
          <Form method="post">
            <Flex direction="column" gap="4">
              <RadixForm.Field name="email">
                <RadixForm.Label>メールアドレス</RadixForm.Label>
                <RadixForm.Control asChild>
                  <TextField.Root type="email" radius="large" required />
                </RadixForm.Control>
                <RadixForm.Message match="valueMissing">
                  メールアドレスを入力してください
                </RadixForm.Message>
                <RadixForm.Message match="typeMismatch">
                  有効なメールアドレスを入力してください
                </RadixForm.Message>
              </RadixForm.Field>

              <RadixForm.Field name="password">
                <RadixForm.Label>パスワード</RadixForm.Label>
                <RadixForm.Control asChild>
                  <TextField.Root type="password" radius="large" required />
                </RadixForm.Control>
                <RadixForm.Message match="valueMissing">
                  パスワードを入力してください
                </RadixForm.Message>
              </RadixForm.Field>

              {actionData?.error && (
                <Text color="red" size="2">
                  {actionData.error}
                </Text>
              )}

              <RadixForm.Submit asChild>
                <Button disabled={navigation.state === "submitting"}>
                  {navigation.state === "submitting"
                    ? "ログイン中..."
                    : "ログイン"}
                </Button>
              </RadixForm.Submit>
            </Flex>
          </Form>
        </RadixForm.Root>

        <Text color="blue">
          <NavLink to={figmentRoutes.authSendPasswordResetEmail()}>
            パスワードをお忘れですか?
          </NavLink>
        </Text>
      </Flex>
    </TitleBodyContainer>
  );
}
